import { castDraft, produceWithPatches } from 'immer'

import { computeChangesSummary } from './computeChangesSummary'

export function patchRmData(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
  formValues: uui.domain.ui.forms.RmVehicleFormValues,
) {
  if (!unifiedVehicle.hasRoutingLicense) {
    throw new Error('routing license is required to patch rm data')
  }

  const [result, patches] = produceWithPatches(unifiedVehicle, draft => {
    // update vehicle data
    draft.vehicle.color = formValues.rm.color.color
    draft.vehicle.externalId = formValues.rm.name
    draft.vehicle.gpsDeviceId = formValues.rm.deviceId
    draft.vehicle.tracked = formValues.rm.eventTracking
    draft.vehicle.edgeOverride = formValues.rm.liveEta ? 'GPS' : 'ROUTESTEP'
    draft.vehicle.enableSsosr = formValues.rm.eventTracking
  })

  return { unifiedVehicle: castDraft(result), summary: computeChangesSummary(patches) }
}
